<template>
    <div class="vi-subject">
        <input
            type="checkbox"
            class="form-check-input"
            :id="`select-subject-${subject.code}-${id}`"
            :name="`select-${subject.code}-${id}`"
            :disabled="disabled"
            v-model="selected"
            @change="onChange"
        >
        <label class="form-check-label" :for="`select-subject-${subject.code}-${id}`">
            {{ subject.name }} <span v-if="gradeOfEntry == 'Grade 11' && ['Accounting', 'Physical Sciences'].includes(subject.name)"> (Learner must have taken {{ subject.name }} in Grade 10) </span>
        </label>

        <template v-if="subjectChangeValidationRequired && selected">
            <span class="question">Does the learner currently take this subject?</span>

            <div class="change-options">
                <label class="change-yes">
                    <input type="radio" required :name="`${subject.code}-change`" :id="`${subject.code}-yes`" :value="false" v-model="currentlyTakingSubject">
                    Yes
                </label>

                <label class="change-no">
                    <input type="radio" required :name="`${subject.code}-change`" :id="`${subject.code}-no`" :value="true" v-model="currentlyTakingSubject">
                    No
                </label>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ViSubject',
    props: {
        subject: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        gradeOfEntry: {
            type: String,
            default: null,
        },
        subjectChangeValidationRequired: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selected: this.subject.is_selected,
            id: null,
            currentlyTakingSubject: null,
        };
    },
    mounted() {
        this.id = this._uid
    },
    methods: {
        onChange(event) {
            let isSelected = event.srcElement.checked;

            this.$emit('subject-selection', { code: this.subject.code, isSelected, });

            if (!isSelected) {
                this.$emit('subject-change', {
                    code: this.subject.code,
                    change: false,
                });
            }
        },
    },
    computed: {},
    watch: {
        currentlyTakingSubject: function (newVal) {
            this.$emit('subject-change', {
                code: this.subject.code,
                change: newVal,
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.vi-subject {
    display: grid;
    // desktop
    grid-template: "input subject subject"
                   "whitespace question options";

    @media screen and (max-width: 1024px) {
        // mobile
        grid-template: "input subject"
                       "whitespace question"
                       "whitespace options";
        grid-template-columns: 16px auto;
    }

    grid-template-columns: 16px max-content auto;
    grid-column-gap: 4px;

    > .form-check-input {
        grid-area: input;
    }

    > .form-check-label {
        grid-area: subject;
    }

    > .question {
        grid-area: question;
    }

    > .change-options {
        grid-area: options;

        & > label:not(:first-of-type) {
            margin-left: 0.5rem;
        }
    }
}
</style>
<template>
    <form @submit.prevent="onSubmit" class="mb-3">
        <h5 class="text-pastel-blue fw-bold mb-3">Emergency contact details</h5>

        <p class="disclaimer">Registered Counsellors will contact the registered emergency contact. An emergency is considered something which requires support within 1 hour of reporting - if the learner is at risk of harm: Physically, Mentally, Cyber, emotionally, and/or otherwise.</p>

        <div v-if="emergencyContact(student) && !isCurrentlyEditing" class="text-dark-blue">
            <div class="border-bottom-blue mb-2">
                {{ emergencyContact(student).firstName }} {{ emergencyContact(student).lastName }}
            </div>
            <div class="border-bottom-blue mb-2">
                {{ emergencyContact(student).email }}
            </div>
            <div class="border-bottom-blue mb-2">
                {{ dialCodes[emergencyContact(student).mobilePhoneCountry].replace(/[^\d+]/g,'') + emergencyContact(student).mobilePhone }}
            </div>
            <div class="border-bottom-blue mb-2">
                {{ emergencyContact(student).relationshipType }}
            </div>
            <vi-button-spinner
                v-if="activeEmergencyContactForm !== student.id"
                :buttonClass="{'new-btn': true, 'new-btn-dark': true, 'mt-3': true}"
                :buttonText="'Edit'"
                :loading="loading"
                @button-click="toggleForm(student.id)"
            />

            <div v-if="successfullyAdded" class="alert alert-success mb-4 mt-3 text-center">
                Emergency contact saved successfully.
            </div>
        </div>
        <form v-if="activeEmergencyContactForm === student.id" @submit.prevent="addEmergencyContact(student)">
            <div class="input-dark mb-3">
                <label for="name" class="label-176">First Name:</label>
                <input class="account-input-container-dark form-control rounded-pill" type="text" v-model="newEmergencyContact.firstName">
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['firstName']" v-html="errors['firstName'][0]"></p>
            </div>
            <div class="input-dark mb-3">
                <label for="name" class="label-176">Last Name:</label>
                <input class="account-input-container-dark form-control rounded-pill" type="text" v-model="newEmergencyContact.lastName">
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['lastName']" v-html="errors['lastName'][0]"></p>
            </div>
            <div class="input-dark mb-3">
                <label for="email" class="label-176">Email:</label>
                <input class="account-input-container-dark form-control rounded-pill" type="email" v-model="newEmergencyContact.email">
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['email']" v-html="errors['email'][0]"></p>
            </div>
            <label class="label-176">Dialing Code:</label>
            <div class="rounded-pill w-100 d-flex mb-3" :class="inputClass">
                <select
                    class="form-control dial-codes-select"
                    type="text"
                    v-model="newEmergencyContact.mobilePhoneCountry"
                >
                    <option :value="null" selected disabled>Please select</option>
                    <option v-for="(option, key) in dialCodes" :value="key" :key="key">{{ option }}</option>
                </select>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['mobilePhoneCountry']" v-html="errors['mobilePhoneCountry'][0]"></p>
            </div>
            <div class="input-dark mb-3">
                <label for="mobilePhone" class="label-176">Mobile Phone:</label>
                <input class="account-input-container-dark form-control rounded-pill" type="tel" v-model="newEmergencyContact.mobilePhone">
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['mobilePhone']" v-html="errors['mobilePhone'][0]"></p>
            </div>
            <div class="input-dark mb-3">
                <label for="relationship" class="label-176">Relationship:</label>
                <select v-model="newEmergencyContact.relationshipType" class="account-input-container-dark form-control rounded-pill">
                    <option v-for="relationship in relationships" :key="relationship.name" :value="relationship.name" :selected="relationship.name === newEmergencyContact.relationshipType">{{ relationship.name }}</option>
                </select>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['relationshipType']" v-html="errors['relationshipType'][0]"></p>
            </div>
            <div v-if="!successfullyAdded" class="mt-2">
                <button type="submit" class="btn btn-primary edit-btn mb-3">
                    Save
                    <div v-show="loading" class="spinner-border text-light spinner" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
                <button type="button" @click="toggleForm(student.id)" class="btn btn-secondary edit-btn" v-if="activeEmergencyContactForm === student.id">
                    Cancel
                </button>
                <div v-if="emergencyContactError" class="alert alert-danger mt-4">
                    {{ emergencyContactError }}
                </div>
            </div>
        </form>

        <button v-else-if="!emergencyContact(student)" type="button" @click="toggleForm(student.id)" class="btn btn-primary edit-btn ">
            Add
        </button>
    </form>
</template>

<script>
export default {
    name: 'EmergencyContactForm',
    data() {
        return {
            activeEmergencyContactForm: null,
            newEmergencyContact: {},
            successfullyAdded: false,
            emergencyContactError: null,
            isCurrentlyEditing: false,
            loading: false,
            errors: {},
            State: Object.freeze({ VIEWING: 1, EDITING: 2, LOADING: 3 }),
            currentState: null,
        };
    },
    props: {
        guardian: {
            type: Object,
            required: true,
        },
        student: {
            type: Object,
            required: true,
        },
        emergencyContactSubmitUrl: {
            type: String,
            required: true,
        },
        relationships: {
            type: Array,
            required: true,
        },
        inputClass: {
            type: String,
        },
        dialCodes: {
            type: Object,
            required: true,
        },
    },
    methods: {
        addEmergencyContact(student) {
            const isEmailSame = this.newEmergencyContact.email === this.guardian.email;

            if (this.emergencyContact(this.student)) {
                const emergencyContactMobile = this.dialCodes[this.emergencyContact(this.student).mobilePhoneCountry].replace(/[^\d+]/g, '') + this.emergencyContact(student).mobilePhone;
                const isMobileSame = emergencyContactMobile === this.guardian.contact.MobilePhone;

                if (isEmailSame || isMobileSame) {
                    this.emergencyContactError = 'Emergency contact should not have the guardian\'s contact details.';
                    return;
                }
            }

            this.setState(this.State.LOADING);
            this.errors = {};

            axios.post(this.emergencyContactSubmitUrl, {
                emergencyContactId: this.newEmergencyContact.emergencyContactId,
                learnerId: student.id,
                firstName: this.newEmergencyContact.firstName,
                lastName: this.newEmergencyContact.lastName,
                email: this.newEmergencyContact.email,
                mobilePhone: this.newEmergencyContact.mobilePhone,
                mobilePhoneCountry: this.newEmergencyContact.mobilePhoneCountry,
                relationshipType: this.newEmergencyContact.relationshipType,
            }).then(response => {
                this.setState(this.State.VIEWING);
                this.activeEmergencyContactForm = null;
                this.student.emergencyContact = this.newEmergencyContact;
                this.successfullyAdded = true;
            }).catch(error => {
                this.errors = error.response.data.errors;
                this.setState(this.State.EDITING);
            });
        },
        emergencyContact(student) {
            return student.emergencyContact ? student.emergencyContact : null;
        },
        toggleForm(studentId) {
            if (this.currentState === this.State.EDITING) {
                this.setState(this.State.VIEWING);
            } else {
                this.setState(this.State.EDITING);
            }

            this.activeEmergencyContactForm = this.activeEmergencyContactForm === studentId ? null : studentId;
            this.newEmergencyContact = this.emergencyContact(this.student) ?? {};
            this.successfullyAdded = false;
            this.emergencyContactError = null;
        },
        setState(newState) {
            this.currentState = newState;

            switch (newState) {
                case this.State.EDITING:
                    this.isCurrentlyEditing = true;
                    this.loading = false;
                    break;
                case this.State.VIEWING:
                    this.isCurrentlyEditing = false;
                    this.loading = false;
                    break;
                case this.State.LOADING:
                    this.isCurrentlyEditing = true;
                    this.loading = true;
                    break;
            }
        },
    },
}

</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.edit-icon {
    font-size: 1.125rem;
}

.input-light {
    label {
        color: $white;
    }

    input {
        color: $uctDarkBlue;

        &::placeholder {
            color: $white;
        }

        &:disabled, &[readonly] {
            color: $white;
        }
    }
}

.input-dark {
    label {
        color: $uctDarkBlue;
    }

    input {
        color: $uctDarkBlue;

        &::placeholder {
            color: $pastel-blue;
        }

        &:disabled, &[readonly] {
            color: $pastel-blue;
        }
    }
}

.edit-btn {
    border: none;
    border-left: 1px solid $white;
    outline: none;
    box-shadow: none;
    background-color: $pastel-light-blue;
    color: $white;
    border-radius: 50rem;
    min-width: 80px;
    width: 100%;
}

.btn-white {
    background-color: $white;
    color: $pastel-blue;
    padding: 10px 40px;
    margin-top: 30px;
}

.spinner {
    width: 20px;
    height: 20px;
}

.border-style-solid {
    border-style: solid;
}

.label-176 {
    display: inline-block;
    width: 176px;
}

.border-bottom-blue {
    border-bottom: 1px solid $uctDarkBlue;
}
.account-input-container-dark {
    border: 1px solid $uctDarkBlue;
}
.dial-codes-select {
    border-radius: 50rem;

    &:focus {
        box-shadow: none;
    }
}

.disclaimer {
    font-size: 0.9rem;
}
</style>

<template>

    <div class="modal looker-modal fade" id="connect-and-grow-view" tabindex="-1" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content overflow-clip bg-transparent border-0">
                <div class="modal-header">
                    <h5 class="modal-title">Connect & Grow Schedule</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="!loading">
                    <test-email-field v-if="!isProduction" v-on:reload-data="updateSubstituteEmail" ></test-email-field>
                    <h2 class="mb-3">{{ term }}</h2>

                    <div class="table-responsive" v-if="courseData.length > 0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th v-for="header in headers" :key="header">{{ header }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, key) in courseData" :key="key">
                                    <td class="session-date">{{ formatDateTime(data.date_of_session) }}</td>
                                    <td>{{ data.session_topic }}</td>
                                    <td>
                                        <i class="bi bi-circle-fill" :class="{
                                            'text-success': data.attendance === 'Attended',
                                            'text-danger': data.attendance === 'Not Attended',
                                            'text-secondary': data.attendance === 'Upcoming'
                                        }"></i> 
                                        {{ data.attendance }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="text-center" v-else>No Data to display</p>
                </div>
                <div class="modal-body" v-else>
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import moment from 'moment';
const endpoints = window.endpoints.connectAndGrow;

export default {
    name: "EnrolledConnectAndGrow",
    data() {
        return {
            loading: false,
            modal: null,
            term: '',
            headers: [
                'Session Date',
                'Topic',
                'Attendance',
            ],
            courseData: [],
            initialized: false,
            currentStudentEmail: null,
        }
    },
    props: {
        isProduction: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        document.body.addEventListener('connectAndGrowView', this.init);
    },
    methods: {
        init(event) {
            if (this.currentStudentEmail === event.detail.studentEmail) {
                return;
            }

            this.currentStudentEmail = event.detail.studentEmail;
            this.fetchData();
        },

        fetchData() {
            this.courseData = [];
            this.term = '';

            this.loading = true;

            axios.get(endpoints.fetchDataUrl + `?studentEmail=${this.currentStudentEmail}`).then(response => {
                this.loading = false;
                this.courseData = response.data.courseData;
                this.term = response.data.term;
                this.initialized = true;
            }).catch(error => {
                this.loading = false;
            });
        },
        formatDateTime(dateTime) {
            return moment(dateTime).format('DD MMMM YYYY');
        },
        updateSubstituteEmail(email) {
            this.currentStudentEmail = email;
            this.fetchData();
        },
    }
}
</script>
<style lang="scss" scoped>
    @import '../../../sass/abstract/colours.scss';

    thead {
        background-color: $pastel-blue;
        color: $white;
        font-weight: bold;
    }

    .session-date {
        background-color: $pastel-light-blue;
        color: $white;
    }

    .modal-title {
        color: $black;
        font-weight: bold;
        font-size: 24px;
    }

    .modal-body h2 {
        color: $grey;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
    }

</style>

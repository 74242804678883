export const NON_COMPLIANT = 'non-compliant';
export const COMPLIANT = 'compliant';
export const CHANGING_SUBJECTS = 'changing-subjects';

const GRADE_8 = 'Grade 8';
const GRADE_9 = 'Grade 9';
const GRADE_10 = 'Grade 10';
const GRADE_11 = 'Grade 11';
const GRADE_12 = 'Grade 12';

export default class ChangingSubjectRulesService {
    requiresValidation(grade, term) {
        let firstOrSecondTermInGrade8or9 = (term === 1 || term === 2) && (grade === GRADE_8 || grade === GRADE_9);
        let firstTermInGrade10 = term === 1 && grade === GRADE_10;

        if (firstOrSecondTermInGrade8or9 || firstTermInGrade10) {
            return false;
        }

        let isTerm3 = term === 3;
        let isTerm2AndOn = term === 1 || term === 2 || isTerm3;

        if ((grade === GRADE_10 && isTerm2AndOn) || grade === GRADE_11 || grade === GRADE_12 || isTerm3) {
            return true;
        }

        return false;
    }

    isCompliant(grade, term, subjectChanges, hasLifeOrientation) {
        let numSubjectChanges = subjectChanges.length;

        if (term === 3 && numSubjectChanges > 0) {
            return NON_COMPLIANT;
        }

        let term2AndOn = term === 2 || term === 3 || term === 4;

        if (grade === GRADE_10 && term2AndOn) {
            if (subjectChanges.includes('MAT')) {
                return NON_COMPLIANT;
            }
        }

        if (grade === GRADE_11 || grade === GRADE_12) {
            if (subjectChanges.includes('PHS') || subjectChanges.includes('ACC') || subjectChanges.includes('MAT') || !hasLifeOrientation) {
                return NON_COMPLIANT; // Can't change subjects to PHS, ACC or Math now.
            }
        }

        if ((grade === GRADE_10 && term2AndOn) || grade === GRADE_11) {
            if (numSubjectChanges === 1 || numSubjectChanges === 2) {
                return CHANGING_SUBJECTS;
            }

            if (numSubjectChanges > 2) {
                return NON_COMPLIANT;
            }
        }

        if (grade === GRADE_12) {
            const unchangableSubjects = ['ACC', 'HIS', 'CAT', 'PHS', 'LSC', 'VRT', 'ITC', 'LOR', 'AFR', 'ZLU', 'XHO', 'STO'];

            if (numSubjectChanges > 1 || hasLifeOrientation === false || subjectChanges.some(subject => unchangableSubjects.includes(subject))) {
                return NON_COMPLIANT;
            }

            if (numSubjectChanges === 1) {
                return CHANGING_SUBJECTS;
            }
        }

        return COMPLIANT;
    }
}

<template>
    <div class="p-3" :key="refreshKey">
        <h5 class="text-pastel-blue fw-bold mb-3">Learner Address</h5>

         <div v-if="!isEditing">
            <div>
                <p><strong>Street</strong>: <br> {{ addressFields.address || 'Not set' }}</p>
                <p><strong>Suburb</strong>: <br> {{ addressFields.address_suburb || 'Not set' }}</p>
                <p><strong>City</strong>: <br> {{ addressFields.address_city || 'Not set' }}</p>
                <p><strong>Province</strong>: <br> {{ addressFields.address_province || 'Not set' }}</p>
                <p><strong>Postal Code</strong>: <br> {{ addressFields.address_postal_code || 'Not set' }}</p>
                <p><strong>Country</strong>: <br> {{ addressFields.country || 'Not set' }}</p>
            </div>

            <vi-button-spinner
                :buttonClass="{'new-btn': true, 'new-btn-dark': true, 'mt-3': true}"
                :buttonText="'Edit'"
                :loading="loading"
                @button-click="startEditing"
            />
        </div>

        <div v-else>
            <button
                class="new-btn-no-border mb-3"
                @click="copyFromGuardian"
            >
            <i class="bi bi-copy"></i> Copy from Guardian
            </button>

            <vi-address-field-group
                :key="refreshKey"
                id="student-address"
                :location-bounds="location ? location.getBounds() : null"
                :errors="addressErrors"
                :address-fields="addressFields"
                v-bind.sync="addressFields"
                :countries="countries"
                :provinces="provinces"
            >
            </vi-address-field-group>

            <vi-button-spinner
                :buttonClass="{'new-btn': true, 'new-btn-dark': true, 'mt-3': true}"
                :buttonText="'Save Changes'"
                :loading="loading"
                @button-click="save"
            />
            <vi-button-spinner
                v-if="!loading"
                :buttonClass="{'new-btn-outline': true, 'mt-3': true}"
                :buttonText="'Discard'"
                :loading="loading"
                @button-click="discard"
            />
        </div>
       
    </div>
</template>
<script>
import axios from 'axios';

const endpoints = window.endpoints.learnerAddress;
export default {
    name: 'LearnerAddressForm',
    data() {
        return {
            loading: true,
            isEditing: false,
            refreshKey: 0,
            savedSuccessfully: false,
            errorMessage: null,
            location: null,
            addressErrors: null,
            addressFields: {
                address: '',
                address_suburb: '',
                address_city: '',
                address_province: '',
                address_postal_code: '',
                country: '',
            },
            learnerSavedAddress: {
                address: '',
                address_suburb: '',
                address_city: '',
                address_province: '',
                address_postal_code: '',
                country: '',
            },
            guardianAddressFields: {
                address: '',
                address_suburb: '',
                address_city: '',
                address_province: '',
                address_postal_code: '',
                country: '',
            },
            countries: {},
            provinces: [],
        };
    },
    props: {
        learner: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async fetchAdress() {
            this.loading = true;
            this.errorMessage = null;
            this.addressErrors = null;
            
            try {
                const response = await axios.get(`${endpoints.url}?studentId=${this.learner.id}`);

                const splitAddress = response.data.MailingStreet.split(',');

                this.addressFields.address = splitAddress[0] ? splitAddress[0].trim() : '';
                this.addressFields.address_suburb = splitAddress[1] ? splitAddress[1].trim() : '';
                this.addressFields.address_city = response.data.MailingCity;
                this.addressFields.address_province = response.data.MailingState;
                this.addressFields.address_postal_code = response.data.MailingPostalCode;
                this.addressFields.country = response.data.MailingCountry;
                this.learnerSavedAddress = JSON.parse(JSON.stringify(this.addressFields));
                this.countries = response.data.countries;
                this.provinces = response.data.provinces;

                const guardianAddress = response.data.guardianMailingStreet.split(',');
                this.guardianAddressFields = {
                    address: guardianAddress[0] ? guardianAddress[0].trim() : '',
                    address_suburb: guardianAddress[1] ? guardianAddress[1].trim() : '',
                    address_city: response.data.guardianMailingCity,
                    address_province: response.data.guardianMailingState,
                    address_postal_code: response.data.guardianMailingPostalCode,
                    country: response.data.guardianMailingCountry,
                };
            } catch (error) {
                this.errorMessage = error.response?.data?.message || 'Failed to load student address';
            } finally {
                this.loading = false;
            }
        },
        startEditing() {
            this.isEditing = true;
        },
        validateAddress() {
            this.addressErrors = null;
            var hasErrors = false;

            if (!this.addressFields.address) {
                this.addressErrors = {
                    ...this.addressErrors,
                    address: ['The address line 1 field is required'],
                };
                hasErrors = true;
            }

            if (!this.addressFields.address_suburb) {
                this.addressErrors = {
                    ...this.addressErrors,
                    suburb: ['The suburb field is required'],
                };
                hasErrors = true;
            }

            if (!this.addressFields.address_city) {
                this.addressErrors = {
                    ...this.addressErrors,
                    city: ['The city field is required'],
                };
                hasErrors = true;
            }

            if (!this.addressFields.address_province) {
                this.addressErrors = {
                    ...this.addressErrors,
                    province: ['The province field is required'],
                };
                hasErrors = true;
            }

            if (!this.addressFields.address_postal_code) {
                this.addressErrors = {
                    ...this.addressErrors,
                    postal_code: ['The postal code field is required'],
                };
                hasErrors = true;
            }

            if (!this.addressFields.country) {
                this.addressErrors = {
                    ...this.addressErrors,
                    country: ['The country field is required'],
                };
                hasErrors = true;
            }

            return !hasErrors;
        },
        save() {
            if (this.validateAddress()) {
                this.loading = true;
                this.errorMessage = null;
                
                axios.post(endpoints.url, {
                    studentId: this.learner.id,
                    MailingStreet: `${this.addressFields.address}, ${this.addressFields.address_suburb}`,
                    MailingCity: this.addressFields.address_city,
                    MailingState: this.addressFields.address_province,
                    MailingPostalCode: this.addressFields.address_postal_code,
                    MailingCountry: this.addressFields.country,
                }).then(() => {
                    this.savedSuccessfully = true;
                    this.isEditing = false;
                    this.learnerSavedAddress = JSON.parse(JSON.stringify(this.addressFields));
                    this.refreshKey++;
                }).catch((error) => {
                    this.errorMessage = error.response?.data?.message || 'Failed to save student address';
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        discard() {
            this.isEditing = false;
            this.addressFields = JSON.parse(JSON.stringify(this.learnerSavedAddress));
            this.refreshKey++;
        },
        copyFromGuardian() {
            this.addressFields = JSON.parse(JSON.stringify(this.guardianAddressFields));
            this.refreshKey++;
        },
        getGeoLocate() {
            //do we support geolocation
            if(!("geolocation" in navigator)) {
                console.error(err.message);
                return;
            }
            // get position
            navigator.geolocation.getCurrentPosition(pos => {
                let geolocation = {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude
                };
                this.location = new google.maps.Circle({
                    center: geolocation,
                    radius: pos.coords.accuracy
                });
            }, err => {
                console.log(err.message);
            })
        },
    },
    async mounted() {
        this.getGeoLocate();
        this.loading = false;

        await this.fetchAdress();

        window.addEventListener('beforeunload', (event) => {
            if (this.isEditing) {
                event.preventDefault();
                event.returnValue = '';
            }
        });
    },
}
</script>
<template>
    <div class="p-3">
        <h5 class="text-pastel-blue fw-bold mb-3">Matric Exam Venue</h5>

        <vi-alert v-if="!isDeadlinePassed">Please note: If you do not select your learner's exam venue preference by <strong>{{ softDeadline }}</strong>, their venue will be allocated based on their registered physical address.</vi-alert>
        <vi-alert v-else>The exam venue selection deadline has passed. For questions, please contact Student Records Office on <a href="mailto:sro@uctohs.valentureonline.com">sro@uctohs.valentureonline.com</a>.</vi-alert>

        <!-- Display mode -->
        <div v-if="!isEditing">
            <div v-for="(venue, index) in selectedVenues" :key="index">
                <p><strong>Exam Venue {{ labelChoice(index) }}</strong>: <br> {{ venue || 'Not selected' }}</p>
            </div>

            <vi-button-spinner
                v-if="!isDeadlinePassed"
                :buttonClass="{'new-btn' : true, 'new-btn-dark': true, 'mt-3': true}"
                :buttonText="'Edit'"
                :loading="loading"
                @button-click="startEditing"
            />
        </div>

        <!-- Editing mode -->
        <div v-else>
            <div class="form-floating mb-2" v-for="(venue, index) in selectedVenues" :key="index">
                <select v-model="selectedVenues[index]" class="form-select new-input" :required="index < 2" @change="validateUniqueChoices">
                    <option v-for="(venueOption, key) in venueOptions" :key="key" :value="venueOption">
                        {{ venueOption }}
                    </option>
                </select>

                <label :for="'examVenue' + index" class="form-label">Exam Venue: {{ labelChoice(index) }}</label>
            </div>

            <vi-button-spinner
                :buttonClass="{'new-btn' : true, 'new-btn-dark': true, 'mt-3': true}"
                :buttonText="'Save'"
                :loading="loading"
                @button-click="submit"
            />
        </div>

        <!-- Success message -->
        <vi-alert v-if="savedSuccessfully" class="mt-2" type="vi-warn">
            Your exam venue options have been saved successfully.
        </vi-alert>

        <!-- Error messages -->
        <vi-alert v-if="errorMessage" class="mt-2" type="vi-error">
            {{ errorMessage }}
        </vi-alert>
    </div>
</template>

<script>
import axios from 'axios';

const endpoints = window.endpoints.examVenue;

export default {
    name: 'ExamVenue',
    props: {
        studentId: { type: String, required: true },
    },
    data() {
        return {
            selectedVenues: ['', '', ''],
            venueOptions: [],
            loading: false,
            isEditing: false,
            savedSuccessfully: false,
            errorMessage: null,
            isDeadlinePassed: false,
            softDeadline: '14 March 2025',
        };
    },
    methods: {
        async fetchExamVenues() {
            this.loading = true;
            this.errorMessage = null;
            
            try {
                const response = await axios.get(`${endpoints.url}?studentId=${this.studentId}`);
                this.venueOptions = response.data.examVenueOptions;
                this.selectedVenues = response.data.selectedVenues || ['', '', ''];
                this.isDeadlinePassed = response.data.isHardDealinePassed;
                this.softDeadline = response.data.softDeadline;
            } catch (error) {
                this.errorMessage = error.response?.data?.message || 'Failed to load exam venues';
            } finally {
                this.loading = false;
            }
        },
        startEditing() {
            this.isEditing = true;
        },
        validateUniqueChoices() {
            const uniqueVenues = new Set(this.selectedVenues.filter(v => v));
            if (uniqueVenues.size !== this.selectedVenues.filter(v => v).length) {
                this.errorMessage = 'Each exam venue choice must be unique.';
            } else {
                this.errorMessage = null;
            }
        },
        async submit() {
            if (!this.selectedVenues[0] || this.selectedVenues[0] == '') {
                this.errorMessage = 'Please select your 1st choice exam venue.';
                return;
            }

            if (!this.selectedVenues[1] || this.selectedVenues[1] == '') {
                this.errorMessage = 'Please select your 2nd choice exam venue.';
                return;
            }

            const uniqueVenues = new Set(this.selectedVenues.filter(v => v));
            if (uniqueVenues.size !== this.selectedVenues.filter(v => v).length) {
                this.errorMessage = 'Each exam venue choice must be unique.';
                return;
            }

            this.loading = true;
            this.savedSuccessfully = false;
            this.errorMessage = null;

            try {
                const response = await axios.post(endpoints.url, {
                    studentId: this.studentId,
                    venues: this.selectedVenues,
                });

                this.venueOptions = response.data.examVenueOptions;
                this.selectedVenues = response.data.selectedVenues || ['', '', ''];
                this.isEditing = false;
                this.savedSuccessfully = true;
            } catch (error) {
                this.errorMessage = error.response?.data?.message || 'Failed to save exam venues';
            } finally {
                this.loading = false;
            }
        },
        labelChoice(index) {
            const labels = ['1st Choice', '2nd Choice', '3rd Choice'];
            return labels[index] || `${index + 1}th Choice`;
        }
    },
    mounted() {
        this.fetchExamVenues();

        window.addEventListener('beforeunload', (event) => {
            if (this.isEditing) {
                event.preventDefault();
                event.returnValue = '';
            }
        });
    },
};
</script>

<style lang="scss" scoped>
</style>